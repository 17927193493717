:root {
  --primary-color: #4f7942;
  --secondary-color: green;
  --primary-text-color: black;
}

.bg-black {
  background-color: #ddd;
}

.cardo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  height: 275px;
  overflow: hidden;
  width: 30%;
  padding: 25px;

  .category-img {
    min-width: 100%;
    max-width: 40%;
    min-height: 100%;
    opacity: 0.5;
    transition: all, 0.3s;
  }

  .card-img-overlay {
    .category-card-title {
      bottom: 50%;
      color: white;
      left: 20px;
      position: absolute;
      text-shadow: 2px 2px 10px #000;
    }

    .btn-category-card {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  }
}

.d-ml-4{
  margin-right: 25px;
}

.dropdown-item:hover, .dropdown-item:focus{
  background-color: #085a4a !important;
  color: #fff !important;
}

footer {
  position: relative;
  bottom: 0px;
}

.loginContainer {
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./assets/images/food.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  bottom: 0; 
}
.loginContainer::after {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  align-items: center;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);

}

.loginContainer-content {
  position: relative;
  z-index: 2;
  width: 500px;
  height: 590px;
  background: rgb(0, 0, 0, 0.65);
  margin: 0.5em auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding: 60px 40px;
}
.registerContainer-content {
  position: relative;
  z-index: 2;
  width: 450px;
  height: 650px;
  top: 20px;
  background: rgb(0, 0, 0, 0.65);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding: 60px 65px;
}

.header-title {
  font-weight: 70px;
  position: relative;
  left: 125px;
  margin-top: 15px;
  margin-bottom: -25px;
}

.bg-footer {
  background-color: #212121;
  padding: 50px 0 30px;
  margin-top: 0;
}
.footer-heading {
  letter-spacing: 2px;
}

.footer-link a {
  color: #acacac;
  line-height: 40px;
  font-size: 14px;
  transition: all 0.5s;
}

.footer-link a:hover {
  color: #1bbc9b;
}

.contact-info {
  color: #acacac;
  font-size: 14px;
}

.sidebar-background {
  background-color: rgb(16 49 11)
}

.sidebarToggleWidth {
  margin-left: 10.5%;
}

.footer-social-icon {
  font-size: 15px;
  height: 34px;
  width: 34px;
  line-height: 34px;
  border-radius: 3px;
  text-align: center;
  display: inline-block;
}

.facebook {
  background-color: #4e71a8;
  color: #ffffff;
}

.twitter {
  background-color: #55acee;
  color: #ffffff;
}

.google {
  background-color: #d6492f;
  color: #ffffff;
}

.apple {
  background-color: #424041;
  color: #ffffff;
}

.footer-alt {
  color: #acacac;
}

.footer-heading {
  position: relative;
  padding-bottom: 12px;
}

.footer-heading:after {
  content: "";
  width: 25px;
  border-bottom: 1px solid #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  border-bottom: 1px solid #1bbc9b;
}

@media screen and (max-width: 1600px) {
  .sidebarToggleWidth {
    margin-left: 17.5%;
  }

}


@media screen and (max-width: 1440px) {
  .sidebarToggleWidth {
    margin-left: 18%;
  }

}

@media screen and (max-width: 1024px) {
  .sidebarToggleWidth {
    margin-left: 25%;
  }

}

@media screen and (max-width: 480px) {
  #dashboard {
    margin-top: 0px;
  }
  .sidebarToggleWidth {
    margin-left: 80%;
  }
  
}

@media screen and (max-width: 600px) {

  .root{
    /* width: 100% !important; */
  }
  .sm-mobile-sidebar{
    height: 100vh;
    width: 36vh !important;
    background-color: rgb(0, 38, 76);
    position: fixed !important;
    z-index: 10;
  }

  .brand-img-2{
    display: none !important;
  }

  .brand-container {
    flex-direction: column;
    width: 100% !important;
    right: 0 !important;
  }

  .brand-header {
    align-content: center;
    left: 0;
    position: static;
  }

  .main-content{
    padding: 0 !important;
    margin: auto !important;
    position: static !important;
    float: none !important;;
    width: 90% !important; 
  }

  #register-form-container{
    padding: 0 !important;
    margin: 0 !important;
  }

  .rown > div {
    width: 110% !important;
  }

  .nicfostText{
    margin: 0 !important;
    padding-bottom: 15px !important;
  }

  .alert{
    top: 0px !important;
  }

  .reg-body{
    padding: 0px !important;
  }

.sidebarToggleWidth {
    margin-left: 0%;
  }  

}

@media screen and (min-width: 601px) {
  #dash-menu{
    display: none !important;
  }


}


@media screen and (min-width: 990px) {
  #dashboard {
    margin-top: 81px;
  }

  .loginContainer{
    padding-top: 2.5rem;
  }

  .brand-header{
    font-size: 22px;
    margin: auto !important;
    font-weight: 500;
  }
}
