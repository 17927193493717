/* .react-form-wizard .wizard-tab-content {
  text-align: left;
}

.react-form-wizard .wizard-nav-pills {
  /* padding-top: 20px;
  padding-bottom: 20px; */
/* background-color: #147332; */
/* border-radius: var(--bs-border-radius);
} */

.react-form-wizard .wizard-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  /* text-align: center; */
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  color: white;
}
.react-form-wizard .wizard-btn.disabled,
.react-form-wizard .wizard-btn[disabled],
fieldset[disabled] .react-form-wizard .wizard-btn {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.react-form-wizard * {
  box-sizing: border-box;
}
.react-form-wizard a {
  text-decoration: none;
}
.react-form-wizard .wizard-nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.react-form-wizard .wizard-nav > li {
  position: relative;
  display: block;
}
.react-form-wizard .wizard-nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}
.react-form-wizard .wizard-nav > li > a:hover,
.react-form-wizard .wizard-nav > li > a:focus {
  text-decoration: none;
  background-color: #eee;
}
.react-form-wizard .wizard-nav > li.disabled > a {
  color: #777;
}
.react-form-wizard .wizard-nav > li.disabled > a:hover,
.react-form-wizard .wizard-nav > li.disabled > a:focus {
  color: #777;
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed;
}
.react-form-wizard .wizard-progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px #00000026;
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.react-form-wizard .wizard-btn,
.react-form-wizard .navbar .navbar-nav > li > a.wizard-btn {
  box-sizing: border-box;
  border-width: 2px;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 12px;
  min-width: 140px;
}
.react-form-wizard .wizard-btn:hover,
.react-form-wizard .wizard-btn:focus,
.react-form-wizard .navbar .navbar-nav > li > a.wizard-btn:hover,
.react-form-wizard .navbar .navbar-nav > li > a.wizard-btn:focus {
  outline: 0 !important;
}
.react-form-wizard .wizard-nav-pills {
  margin-top: 0;
  position: relative;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}
.react-form-wizard .wizard-nav-pills li,
.react-form-wizard .wizard-nav-pills a {
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
}
.react-form-wizard .wizard-nav-pills a {
  display: flex;
}
.react-form-wizard .wizard-nav-pills > li > a {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 auto;
  color: #fff;
  position: relative;
  top: 3px;
}
.react-form-wizard .wizard-nav-pills > li > a:hover,
.react-form-wizard .wizard-nav-pills > li > a:focus {
  background-color: transparent;
  color: #e0e0e0;
  outline: 0 !important;
}
.react-form-wizard .wizard-nav-pills > li > a.disabled {
  pointer-events: none;
  cursor: default;
}
.react-form-wizard .wizard-nav-pills > li.active > a,
.react-form-wizard .wizard-nav-pills > li.active > a:hover,
.react-form-wizard .wizard-nav-pills > li.active > a:focus {
  background-color: transparent;
  -webkit-transition: font-size 0.2s linear;
  -moz-transition: font-size 0.2s linear;
  -o-transition: font-size 0.2s linear;
  -ms-transition: font-size 0.2s linear;
  transition: font-size 0.2s linear;
}
.react-form-wizard .wizard-nav-pills > li.active > a .wizard-icon,
.react-form-wizard .wizard-nav-pills > li.active > a:hover .wizard-icon,
.react-form-wizard .wizard-nav-pills > li.active > a:focus .wizard-icon {
  color: #fff;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
/* .react-form-wizard {
  padding-bottom: 20px;
} */
.react-form-wizard .is_error {
  border-color: #c84513 !important;
}
.react-form-wizard .is_error .icon-container {
  background: #c84513 !important;
}
.react-form-wizard.xs .wizard-icon-circle {
  width: 40px;
  height: 40px;
  font-size: 16px;
}
.react-form-wizard.xs .wizard-icon-circle.tab_shape {
  height: 25px;
}
.react-form-wizard.xs .wizard-nav-pills > li.active > a .wizard-icon {
  font-size: 16px;
}
.react-form-wizard.xs .wizard-navigation .wizard-progress-with-circle {
  position: relative;
  top: 25px;
  height: 4px;
}
.react-form-wizard.sm .wizard-icon-circle {
  width: 50px;
  height: 50px;
  font-size: 20px;
}
.react-form-wizard.sm .wizard-icon-circle.tab_shape {
  height: 30px;
}
.react-form-wizard.sm .wizard-nav-pills > li.active > a .wizard-icon {
  font-size: 20px;
}
.react-form-wizard.sm .wizard-navigation .wizard-progress-with-circle {
  position: relative;
  top: 30px;
  height: 4px;
}
.react-form-wizard.md .wizard-icon-circle {
  width: 70px;
  height: 70px;
  font-size: 24px;
}
.react-form-wizard.md .wizard-icon-circle.tab_shape {
  height: 40px;
}
.react-form-wizard.md .wizard-nav-pills > li.active > a .wizard-icon {
  font-size: 24px;
}
.react-form-wizard.md .wizard-navigation .wizard-progress-with-circle {
  position: relative;
  top: 40px;
  height: 4px;
}
.react-form-wizard.lg .wizard-icon-circle {
  width: 90px;
  height: 90px;
  font-size: 28px;
}
.react-form-wizard.lg .wizard-icon-circle.tab_shape {
  height: 50px;
}
.react-form-wizard.lg .wizard-nav-pills > li.active > a .wizard-icon {
  font-size: 28px;
}
.react-form-wizard.lg .wizard-navigation .wizard-progress-with-circle {
  position: relative;
  top: 50px;
  height: 4px;
}
.react-form-wizard .wizard-icon-circle {
  font-size: 18px;
  border: 3px solid #fff;
  border-radius: 50%;
  font-weight: 600;
  width: 70px;
  height: 70px;
  background-color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
}
.react-form-wizard .wizard-icon-circle.square_shape {
  border-radius: 0;
}
.react-form-wizard .wizard-icon-circle.tab_shape {
  width: 100%;
  min-width: 100px;
  height: 40px;
  border: none;
  background-color: #f3f2ee;
  border-radius: 0;
}
.react-form-wizard .wizard-icon-circle .wizard-icon-container {
  display: flex;
  justify-content: center;
  flex: 1;
  border-radius: 50%;
  margin: -3px;
}
.react-form-wizard .wizard-icon-circle .wizard-icon-container.square_shape {
  border-radius: 0;
}
.react-form-wizard .wizard-icon-circle .wizard-icon-container.tab_shape {
  border-radius: 0;
  margin: 0;
}
.react-form-wizard .wizard-icon-circle .wizard-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-form-wizard .wizard-tab-content {
  min-height: 100px;
}
.react-form-wizard .wizard-tab-content,
.wizard-card-footer {
  padding: 50px 20px 20px;
}

.react-form-wizard .wizard-header {
  padding: 15px;
  position: relative;
  border-radius: 3px 3px 0 0;
  text-align: center;
}
.react-form-wizard .wizard-title {
  color: #fff;
  font-weight: 300;
  /* margin: 0; */
  text-align: center;
}
.react-form-wizard .category {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
  text-align: center;
}
.react-form-wizard .wizard-navigation .wizard-progress-with-circle {
  position: relative;
  top: 40px;
  height: 4px;
}
.react-form-wizard
  .wizard-navigation
  .wizard-progress-with-circle
  .wizard-progress-bar {
  box-shadow: none;
  -webkit-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.react-form-wizard .clearfix:after {
  content: "";
  clear: both;
  display: table;
}
.react-form-wizard .wizard-card-footer {
  padding: 0 20px;
}
.react-form-wizard .wizard-card-footer .wizard-footer-left {
  float: left;
}
.react-form-wizard .wizard-card-footer .wizard-footer-right {
  float: right;
}
@media screen and (max-width: 350px) {
  .react-form-wizard .wizard-card-footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .react-form-wizard .wizard-card-footer .wizard-footer-left,
  .react-form-wizard .wizard-card-footer .wizard-footer-right {
    float: none;
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .react-form-wizard .wizard-card-footer .wizard-footer-right button {
    margin-top: 10px;
  }
}
.react-form-wizard.vertical .wizard-card-footer {
  display: block;
}
.react-form-wizard.vertical .wizard-nav-pills {
  flex-direction: column;
}
.react-form-wizard.vertical .wizard-navigation {
  display: flex;
  flex-direction: row;
}
.react-form-wizard.vertical .wizard-card-footer {
  padding-top: 30px;
}

.wizard-card-footer .btn {
  color: var(--bs-success-text-emphasis);
}
