.payment-summary-container {
    background-color: #f7fafc; /* bg-gray-100 */
    display: flex; /* flex */
    align-items: center; /* items-center */
    justify-content: center; /* justify-center */
    min-height: 100vh; /* min-h-screen */
  }
  
  .payment-summary-content {
    background-color: #ffffff; 
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1); /* shadow-lg */
    border-radius: 0.5rem; /* rounded-lg */
    padding: 1.5rem; /* p-6 */
    width: 100%; /* w-full */
    max-width: 42rem; /* max-w-2xl */
    min-height: 300px; /* min-h-[300px] */
  }
  
  .title {
    font-size: 1.5rem; /* text-2xl */
    font-weight: bold; /* font-bold */
    margin-bottom: 1rem; /* mb-4 */
  }
  
  .spinner-container {
    display: flex; /* flex */
    justify-content: center; /* justify-center */
    align-items: center; /* items-center */
    padding: 5rem 0; /* py-20 */
  }
  
  .spinner {
    font-size: 2rem; /* text-3xl */
    height: 10rem; /* h-40 */
    text-align: center; /* text-center */
  }
  
  .refresh-note {
    border-top: 1px solid #e2e8f0; /* border-t */
    padding-top: 1rem; /* pt-4 */
  }
  