
/* // style readOnly inputs */

input[readOnly] {
    background-color: #d1d2d3 !important;
    cursor: not-allowed;
}


select[disabled] {
    background-color: #d1d2d3 !important;
    cursor: not-allowed;
    color: #808080;
    /* border: none; */
}
